import { IngredientsDataMap, IngredientDataObject } from "../../models/Models";
import productConfig from "../../static/products-config.json";

const OptionCard = (props: { value: string, selected?: boolean, onClick?: Function}) => {
    
    const {value, onClick} = props;

    const addons: IngredientsDataMap = productConfig.addons;
    const addon: IngredientDataObject = addons[value];

    const selected = props.selected ?? false;
    const baseClass = "base-option-card card";
    const selectedClass = "base-option-card card option-selected";

    return (
        <div onClick={() => onClick?.(value)} 
            className={(selected) ? selectedClass : baseClass}>
            {
                (addon.img && addon.img.length > 0) &&
                <img src={addon.img} height={20} alt="" />
            }
            <br />
            <div>{addon.label}</div>
            <div className="option-card-price-line"> &#x20B9; {addon.price}</div>
        </div>
    )
};

export default OptionCard;