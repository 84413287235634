import { logEvent } from "firebase/analytics";
import { useContext, useRef, useState } from "react";
import { setCheckoutStepAction, setCheckoutViewAction, setPaymentLoadingAction } from "../../actions/ActionsList";
import { PayementGatewayDetails } from "../../constants/Constants";
import { analytics } from "../../firebaseConfig";
import { Context } from "../../GlobalStore";
import { CheckoutStep, CheckoutView } from "../../models/Models";
import { createOrder } from "../../services/OrderService";
import { sha512 } from "../../utils/CryptoUtils";
import { getCartPriceWithTax } from "../../utils/PricingUtils";
import CartItemsContainer from "./CartItemsContainer";
import CheckoutShippingDetailsTab from "./CheckoutShippingDetailsTab";

const Step4 = () => {

    const formElement = useRef<HTMLFormElement | null>(null);
    const txnIdRef = useRef<HTMLInputElement | null>(null);

    const {store, dispatch} = useContext(Context);
    const cart = store.cart;
    const { personal } = store.checkout;

    const [paymentLoading, setPaymentLoading] = useState(false);

    const prevStep = () => dispatch(setCheckoutStepAction(CheckoutStep.BILLING_AND_SHIPPING));
    const viewItems = () => dispatch(setCheckoutViewAction(CheckoutView.CHECKOUT_ITEMS));
    const viewShippingDetails = () => dispatch(setCheckoutViewAction(CheckoutView.CHECKOUT_SHIPPING_DETAILS));

    const {salt, merchantKey, surl, furl} = PayementGatewayDetails;
    const amount = `${getCartPriceWithTax(cart, personal.state)}`;
    // const amount = 1;
    const email = personal.emailId;
    const firstName = personal.firstName;
    const lastName = personal.lastName;;
    const phone = personal.phoneNumber;

    const addr1 = personal.addressLine1;
    const addr2 = personal.addressLine2;
    const city = personal.city;
    const zipcode = personal.pincode;

    const makePayment = () => {

        logEvent(analytics, "checkout_progress", {
            orderInitiated: true
        });
        setPaymentLoading(true);

        const cartItemList = Object.entries(cart).map(([key, cartItem]) => cartItem);

        createOrder(cartItemList, personal).then((result) => {
            const { orderId } = result;

            console.log('Created Order Successfully: ' + orderId);

            (document.getElementById('txnid') as HTMLInputElement).value = orderId;
            (document.getElementById('productinfo') as HTMLInputElement).value = orderId;

            const hashInput = `${merchantKey}|${orderId}|${amount}|${orderId}|${firstName}|${email}|||||||||||${salt}`;

            sha512(hashInput).then((hashValue) => {
                logEvent(analytics, "checkout_progress", {
                    paymentInitiated: true
                });
                (document.getElementById('txnhash') as HTMLInputElement).value = hashValue;
                (document.getElementById('coolform') as HTMLFormElement).submit();
                dispatch(setPaymentLoadingAction(true));
            }).catch((e) => {
                console.log('Error Generating Transaction Hash: ', e);
            });
        }).catch((e) => {
            logEvent(analytics, "checkout_progress", {
                orderInitiationError: true
            });
            console.log('Error creating order: ', e);
        });
    }


    const checkoutTabStyle = {
        summary: (store.checkout.view === CheckoutView.CHECKOUT_SUMMARY) ? 'nav-link active' : 'nav-link',
        items: (store.checkout.view === CheckoutView.CHECKOUT_ITEMS) ? 'nav-link active' : 'nav-link',
        shippingDetails: (store.checkout.view === CheckoutView.CHECKOUT_SHIPPING_DETAILS) ? 'nav-link active' : 'nav-link'
    }

    return (
        <>
            <div className="width-90">
                <div className="byob-step" id={"byob-step-1"}>
                    Checkout / Payment<br/>
                    <span className="step-subtitle">Complete payment to confirm your order</span>
                </div>
                <br />
                <div>
                    <ul className="nav nav-tabs" id={'checkout-tabs'}>
                        {/* <li className="nav-item">
                            <div className={checkoutTabStyle.summary} onClick={viewSummary}>Summary</div>
                        </li> */}
                        <li className="nav-item">
                            <div className={checkoutTabStyle.items} onClick={viewItems}>Items</div>
                        </li>
                        <li className="nav-item">
                            <div className={checkoutTabStyle.shippingDetails} onClick={viewShippingDetails}>Shipping</div>
                        </li>
                    </ul>
                    <br />
                    {
                        // (store.checkout.view === CheckoutView.CHECKOUT_SUMMARY) ?
                        // <div>Summary Placeholder</div> : 
                        (store.checkout.view === CheckoutView.CHECKOUT_ITEMS) ?
                        <CartItemsContainer /> :
                        <CheckoutShippingDetailsTab />
                    }
                </div>
                <br />
            </div>
            <div className="bottom-bar-spacer"></div>
            <div className="button-container">
                <button 
                    className="btn btn-sm step-button" 
                    onClick={prevStep}
                >&larr; Back to Shipping</button>
                <form id={'coolform'} ref={formElement} action='https://secure.payu.in/_payment' method='post'>
                    <input type="hidden" name="key" value={merchantKey} />
                    <input ref={txnIdRef} id={'txnid'} type="hidden" name="txnid" value={''} />
                    <input type="hidden" id={'productinfo'} name="productinfo" value={''} />
                    <input type="hidden" name="amount" value={amount} />
                    <input type="hidden" name="email" value={email} />
                    <input type="hidden" name="firstname" value={firstName} />
                    <input type="hidden" name="lastname" value={lastName} />
                    <input type="hidden" name="surl" value={surl} />
                    <input type="hidden" name="furl" value={furl} />
                    <input type="hidden" name="phone" value={phone} />
                    <input type="hidden" id={'txnhash'} name="hash" value={''} />
                    <input type="hidden" name="address1" value={addr1} />
                    <input type="hidden" name="address2" value={addr2} />
                    <input type="hidden" name="city" value={city} />
                    <input type="hidden" name="zipcode" value={zipcode} />
                </form>
                <button id="payment-button" className="btn btn-success btn-sm" onClick={makePayment} disabled={paymentLoading}>
                    {
                        paymentLoading ?
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> :
                        <span>Pay &#x20B9; {amount}</span>
                    }
                    
                </button>
            </div>
        </>
    )
};

export default Step4;