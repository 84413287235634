import { CartItem } from "../models/Models";

export const stringifyCartItem = (item: CartItem) => {
    const bases = item.base;
    const addons = item.addons;
    const sweetener = item.sweetener;

    bases.sort();
    addons.sort();
    sweetener.sort();

    const baseList = bases.join(',');
    const addonsList = addons.join(',');
    const sweetenerList = sweetener.join(',')

    return `bases=${baseList}&addons=${addonsList}&sweetener=${sweetenerList}&quantity=${item.quantity}`;
}

export const parseCartItemString = (cartItemString: string) => {
    // const parts = cartItemString.split('&');
    // const baseString = parts[0];
    // const addonsString = parts[1];
    // const quantityString = parts[2];

    const quantityString = cartItemString.match(/quantity=[0-9]+/i);
    const baseString = cartItemString.match(/bases=[a-z,_]+/i);
    const addonsString = cartItemString.match(/addons=[a-z,_]+/i);
    const sweetenerString = cartItemString.match(/sweetener=[a-z,_]+/i);


    const baseListString = baseString ? baseString[0].split('=')[1] : null;
    const addonsListString = addonsString ? addonsString[0].split('=')[1] : null;
    const sweetenerListString = sweetenerString ? sweetenerString[0].split('=')[1] : null;
    const quantityValueString = quantityString ? quantityString[0].split('=')[1] : null;

    const res = {
        index: -1,
        base: baseListString?.split(',') ?? [],
        addons: (addonsListString != undefined) ? (addonsListString.length > 0 ? addonsListString.split(',') : []) : [],
        sweetener: (sweetenerListString != undefined) ? (sweetenerListString.length > 0 ? sweetenerListString.split(',') : []) : [],
        quantity: parseInt(quantityValueString ?? '0')
    }

    return res;
}

