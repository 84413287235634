import { ByobStep, CheckoutStep, CheckoutView, Personal } from "../models/Models";

export enum Actions {
    SET_BASE = 'SET_BASE',
    SET_ADDON = 'SET_ADDON',
    SET_SWEETENER = 'SET_SWEETENER',
    SET_ORDER_STEP = 'SET_STEP',
    SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP',
    SET_PERSONAL = 'SET_PERSONAL',
    ADD_TO_CART = 'ADD_TO_CART',
    REMOVE_FROM_CART = 'REMOVE_FROM_CART',
    RESET_ORDER = 'RESET_ORDER',
    SET_CHECKOUT_VIEW = 'SET_CHECKOUT_VIEW',
    RESET = 'RESET',
    SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING',
    SET_ITEM_QUANTITY_ACTION = 'SET_ITEM_QUANTITY_ACTION'
}

export const setBaseAction = (base: string[]) => ({
    type: Actions.SET_BASE,
    payload: base
});

export const setAddonAction = (addons: string[]) => ({
    type: Actions.SET_ADDON,
    payload: addons
});

export const setSweetenerAction = (sweetener: string[]) => ({
    type: Actions.SET_SWEETENER,
    payload: sweetener
});

export const setOrderStepAction = (step: ByobStep) => ({
    type: Actions.SET_ORDER_STEP,
    payload: step
});

export const setCheckoutStepAction = (step: CheckoutStep) => ({
    type: Actions.SET_CHECKOUT_STEP,
    payload: step
});

export const setPersonalAction = (personal: Personal) => ({
    type: Actions.SET_PERSONAL,
    payload: personal
});

export const addToCartAction = (product: any) => ({
    type: Actions.ADD_TO_CART,
    payload: product
});

export const removeFromCartAction = (index: number) => ({
    type: Actions.REMOVE_FROM_CART,
    payload: index
});

export const resetOrderAction = () => ({
    type: Actions.RESET_ORDER
});

export const setCheckoutViewAction = (view: CheckoutView) => ({
    type: Actions.SET_CHECKOUT_VIEW,
    payload: view
});

export const resetAction = () => ({
    type: Actions.RESET
});

export const setPaymentLoadingAction = (paymentLoadingStatus: boolean) => ({
    type: Actions.SET_PAYMENT_LOADING,
    payload: paymentLoadingStatus
});

export const setItemQuantityAction = (itemId: number, quantity: number) => ({
    type: Actions.SET_ITEM_QUANTITY_ACTION,
    payload: {
        itemId: itemId,
        quantity: quantity
    }
});