import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../GlobalStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";
import logo from "../../static/images/super192.png";

const Topbar = () => {
    const { store } = useContext(Context);
    
    return (
        <nav className="navbar sticky-top navbar-expand-lg topbar">
            <div className="container-fluid">
                
                <Link to={'/'} className="navbar-brand">
                    <img src={logo} width={80} alt={''} />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={faBars} color={"#9B5DE5"} />
                </button>
                
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/byob">Build your own butter</Link>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#learnmore">Learn More</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#footer">Contact</HashLink>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/cart">Cart ({Object.keys(store.cart).length})</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default Topbar;