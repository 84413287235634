import { logEvent } from "firebase/analytics";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { analytics } from "../firebaseConfig";
import { Context } from "../GlobalStore";
import { CheckoutStep } from "../models/Models";
import CheckoutProgressIndicator from "./byobStepComponents/CheckoutProgressIndicator";
import Step3 from "./byobStepComponents/Step3";
import Step4 from "./byobStepComponents/Step4";
import Loading from "./common/Loading";
import Topbar from "./navigationBarComponents/Topbar";

const Checkout = () => {
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const step: CheckoutStep = store.checkout.step;

    useEffect(() => {
        if (Object.keys(store.cart).length === 0 && store.checkout.paymentLoading === false) {
            navigate('/cart');
        }
    });

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: "checkout", 
            firebase_screen_class: ""
        });
    }, []);

    const CheckoutStepComponent = () => {
        switch(step) {
            case CheckoutStep.BILLING_AND_SHIPPING: return <Step3 />;
            case CheckoutStep.PAYMENT: return <Step4 />;
        }
    }

    return (
        <div className="App">
            <Topbar />
            <div className="topbar-spacer wave2 upside-down"></div>
            <div className="main-container full-width">
                <br />
                {
                    store.checkout.paymentLoading ?
                    <Loading title={''} /> :
                    <>
                        <div className="width-90">
                            <CheckoutProgressIndicator />
                        </div>
                        <div id={'byob-step-content'}>
                            <CheckoutStepComponent />
                        </div>
                    </>
                }
                <br />
            </div>
        </div>
    )
};

export default Checkout;