import { IngredientsDataMap, IngredientDataObject } from "../../models/Models";
import productConfig from "../../static/products-config.json";

const OptionCard = (props: { value: string, selected?: boolean, onClick?: Function}) => {
    
    const {value, onClick} = props;

    const sweeteners: IngredientsDataMap = productConfig.sweeteners;
    const sweetener: IngredientDataObject = sweeteners[value];

    const selected = props.selected ?? false;
    const baseClass = "base-option-card card";
    const selectedClass = "base-option-card card option-selected";

    return (
        <div onClick={() => onClick?.(value)} 
            className={(selected) ? selectedClass : baseClass}>
            {
                (sweetener.img && sweetener.img.length > 0) &&
                <img src={sweetener.img} height={20} alt="" />
            }
            <br />
            <div>{sweetener.label}</div>
            <div className="option-card-price-line"> &#x20B9; {sweetener.price}</div>
        </div>
    )
};

export default OptionCard;