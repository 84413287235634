
const NutritionTag = (props: { label: string; }) => {

    const {label} = props;

    return (
        <span className="nutrition-tag">
            {label}
        </span>
    )
}

export default NutritionTag;