import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PayementGatewayDetails } from "../constants/Constants";
import { CartItem as CartItemModel, OrderDetails, OrderView } from "../models/Models";
import { getOrderDetails } from "../services/OrderService";
import { parseCartItemString } from "../utils/CartUtils";
import { sha512 } from "../utils/CryptoUtils";
import CartItem from "./cartChildComponents/CartItem";
import Note from "./common/Note";
import StatusBadge from "./common/StatusBadge";
import Topbar from "./navigationBarComponents/Topbar";

const CartDetails = (props: { cartItems: CartItemModel[] }) => {
    return (
        <div className="col-sm-12" id={'cart-items'}>
            {
                props.cartItems?.map((item) => {
                    return <CartItem key={item.index} id={item.index} base={item.base} addons={item.addons}  sweetener={item.sweetener} quantity={item.quantity} mode={'order-view'} />
                })
            }
        </div>
    )
}

const ShippingDetails = (props: { orderDetails: OrderDetails }) => {
    const {orderDetails} = props;
    return (
        <div>
            <Note title="Expected Delivery Time" content="4 - 6 business days from date of order." />
            <br />
            <StatusBadge status={orderDetails.deliveryStatus} />
            {
                orderDetails.deliveryStatus === 'shipped' &&
                <>
                    <br />
                    <Note title="Consignment #" content={orderDetails.consignment} />
                    <a href={orderDetails.trackingLink}>Track Shipment</a>
                    <br />
                </>
            }
            <br/>
            <div className="order-details-content-container">
                <div className="order-details-subheading">Shipping to</div>
                <div>{orderDetails.firstName} {orderDetails.lastName}</div>
                <div>{orderDetails.email}</div>
                <div>{orderDetails.phone}</div>
                <br />
                <div>{orderDetails.addr1}</div>
                <div>{orderDetails.addr2}</div>
                <div>{orderDetails.city} - {orderDetails.pincode}</div>
            </div>
        </div>
    )
}

const Order = () => {

    const { orderId } = useParams();

    const [searchparams] = useSearchParams();


    const paymentPending = parseInt(searchparams.get('paymentpending') ?? '0');

    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState({} as OrderDetails);
    const [orderView, setOrderView] = useState((paymentPending) ? OrderView.PAYMENT_DETAILS : OrderView.CART_DETAILS);

    const [paymentLoading, setPaymentLoading] = useState(false);

    const orderDetailsTabStyle = {
        cart: (orderView === OrderView.CART_DETAILS) ? 'nav-link active' : 'nav-link',
        payment: (orderView === OrderView.PAYMENT_DETAILS) ? 'nav-link active' : 'nav-link',
        shipping: (orderView === OrderView.SHIPPING_DETAILS) ? 'nav-link active' : 'nav-link',
    }

    const seconds = orderDetails.orderedOn?._seconds;
    const nanoseconds = orderDetails.orderedOn?._nanoseconds;

    const timestamp = seconds * Math.pow(10,3) + nanoseconds / Math.pow(10,6);

    const orderDate = new Date(timestamp);

    const cartItems = orderDetails?.cart?.map((cartItemString) => parseCartItemString(cartItemString));

    const validOrder = !loading && orderId && Object.keys(orderDetails).length > 0;

    useEffect(() => {
        if(orderId) {
            getOrderDetails(orderId).then((result) => {
                console.log('Order: ', result);
                setOrderDetails(result);
            }).catch((e) => {
                console.log('Error fetching order details: ', e);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [orderId])

    const PaymentDetails = (props: { orderDetails: OrderDetails }) => {
        const {orderDetails} = props;

        const {salt, merchantKey, surl, furl} = PayementGatewayDetails;


        const makePayment = () => {
            if(orderId) {
                // TODO: FIX - setPaymentLoading state change causes payment request to fail with error stating that request params are missing
                // setPaymentLoading(true);
                (document.getElementById('txnid') as HTMLInputElement).value = orderId;
    
                const hashInput = `${merchantKey}|${orderId}|${orderDetails.totalAmount}|${orderId}|${orderDetails.firstName}|${orderDetails.email}|||||||||||${salt}`;
    
                sha512(hashInput).then((hashValue) => {
                    (document.getElementById('txnhash') as HTMLInputElement).value = hashValue;
                    (document.getElementById('coolform') as HTMLFormElement).submit();
                }).catch((e) => {
                    console.log('Error Generating Transaction Hash: ', e);
                });
            }
        }
        
        return (
            <div>
                <StatusBadge status={orderDetails.paymentStatus} />
                <br/>
                <div className="order-details-content-container">
                    <div className="order-details-subheading">Personal Details</div>
                    <div>{orderDetails.firstName} {orderDetails.lastName}</div>
                    <div>{orderDetails.email}</div>
                    <div>{orderDetails.phone}</div>
                    <br />
                    <div>Order Value: {orderDetails.cartAmount}</div>
                    <div>Shipping Charge: {orderDetails.shippingFee}</div>
                    <div>GST @ 12%: {orderDetails.tax}</div>
                    <div>Total: {orderDetails.totalAmount}</div>
                </div>
                <br />
                {
                    orderDetails.paymentStatus !== 'complete' && orderDetails.orderStatus !== 'cancelled' &&
                    <>
                        <form id={'coolform'} action='https://secure.payu.in/_payment' method='post'>
                            <input type="hidden" name="key" value={merchantKey} />
                            <input id={'txnid'} type="hidden" name="txnid" value={''} />
                            <input type="hidden" name="productinfo" value={orderId} />
                            <input type="hidden" name="amount" value={orderDetails.totalAmount} />
                            <input type="hidden" name="email" value={orderDetails.email} />
                            <input type="hidden" name="firstname" value={orderDetails.firstName} />
                            <input type="hidden" name="lastname" value={orderDetails.lastName} />
                            <input type="hidden" name="surl" value={surl} />
                            <input type="hidden" name="furl" value={furl} />
                            <input type="hidden" name="phone" value={orderDetails.phone} />
                            <input type="hidden" id={'txnhash'} name="hash" value={''} />
                            <input type="hidden" name="address1" value={orderDetails.addr1} />
                            <input type="hidden" name="address2" value={orderDetails.addr2} />
                            <input type="hidden" name="city" value={orderDetails.city} />
                            <input type="hidden" name="zipcode" value={orderDetails.pincode} />
                        </form>
                        <button id="payment-button" className="btn btn-success" onClick={makePayment} disabled={paymentLoading}>
                            {
                                paymentLoading ?
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> :
                                <span>Pay &#x20B9; {orderDetails.totalAmount}</span>
                            }
                        </button>
                    </>
                }
            </div>
        )
    }

    return (
        <div className="App">
            <Topbar />
            <div className="topbar-spacer wave2 upside-down"></div>
            <br />
            <div className="order-main-container main-container width-90">
                <Note title={"Note"} content={"The link to your order details has been sent to your email."} />
                <br />

                <div className="order-header">
                    <span>Order</span>
                    <h2>{orderId}</h2>
                </div>

                <div className="order-date">{validOrder && orderDate.toDateString()}</div>

                <div className="status-badges">
                    <StatusBadge status={orderDetails.orderStatus} />
                </div>
                <br />
                <div>
                    <ul className="nav nav-tabs" id={'checkout-tabs'}>
                        <li className="nav-item">
                            <div className={orderDetailsTabStyle.cart} onClick={() => setOrderView(OrderView.CART_DETAILS)}>Cart</div>
                        </li>
                        <li className="nav-item">
                            <div className={orderDetailsTabStyle.payment} onClick={() => setOrderView(OrderView.PAYMENT_DETAILS)}>Payment</div>
                        </li>
                        <li className="nav-item">
                            <div className={orderDetailsTabStyle.shipping} onClick={() => setOrderView(OrderView.SHIPPING_DETAILS)}>Shipping</div>
                        </li>
                    </ul>
                    <br />
                    {
                        
                    }
                </div>
                {   
                    (loading) ?
                    "Loading..." :
                    (validOrder) ?
                    (orderView === OrderView.CART_DETAILS) ?
                    <CartDetails cartItems={cartItems}/> :
                    (orderView === OrderView.SHIPPING_DETAILS) ?
                    <ShippingDetails orderDetails={orderDetails} /> :
                    (orderView === OrderView.PAYMENT_DETAILS) ?
                    <PaymentDetails orderDetails={orderDetails} />:
                    <div></div> :
                    'No order found'
                }
            </div>
            <br />
        </div>
    )
};

export default Order;