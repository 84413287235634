import { useSearchParams } from "react-router-dom";
import { IngredientsDataMap, NutritionDataMap } from "../models/Models";
import Topbar from "./navigationBarComponents/Topbar";
import productConfig from "../static/products-config.json";
import { getProductPrice } from "../utils/PricingUtils";
import NutritionTag from "./productChildComponents/NutritionTag";
import { analytics } from "../firebaseConfig";
import { logEvent } from "firebase/analytics";

const Product = () => {

    const [searchparams] = useSearchParams();

    const baseListString = searchparams.get('bases');
    const addonsListString = searchparams.get('addons');
    const sweetenerListString = searchparams.get('sweetener');

    logEvent(analytics, 'screen_view', {
        firebase_screen: "product", 
        firebase_screen_class: `${baseListString}&${addonsListString}&${sweetenerListString}`
    });

    const bases = (baseListString?.split(',') ?? []).filter((item) => item.trim().length > 0);
    const addons = (addonsListString?.split(',') ?? []).filter((item) => item.trim().length > 0);
    const sweetener = (sweetenerListString?.split(',') ?? []).filter((item) => item.trim().length > 0);

    const productPrice = getProductPrice(bases, addons, sweetener);

    const basesMap: IngredientsDataMap = productConfig.bases;
    const addonsMap: IngredientsDataMap = productConfig.addons;
    const sweetenerMap: IngredientsDataMap = productConfig.sweeteners;

    const microNutrientMap: NutritionDataMap = productConfig.nutrition.nutrients.micro;
    const macroNutrientMap: NutritionDataMap = productConfig.nutrition.nutrients.macro;
    const healthBenefitsMap: NutritionDataMap = productConfig.nutrition.benefits;

    const baseLabel = bases.map((baseKey) => {
        return basesMap[baseKey].label
    }).join(', ');

    const addonsLabel = addons.map((addonKey) => {
        return addonsMap[addonKey].label
    }).join(', ');

    const sweetenerLabel = sweetener.map((sweetenerKey) => {
        return sweetenerMap[sweetenerKey].label
    }).join(', ');

    let ingredientIdentifierObjectList : { name: string; image: string ; }[] = [];

    let microNutrientKeyList: string[] = [];
    let macroNutrientKeyList: string[] = [];
    let healthBenefitsKeyList: string[] = [];

    bases.forEach((baseKey) => {
        ingredientIdentifierObjectList.push({
            name: basesMap[baseKey].label,
            image: basesMap[baseKey].img
        });

        microNutrientKeyList = microNutrientKeyList.concat(basesMap[baseKey].nutrition.micro);
        macroNutrientKeyList = macroNutrientKeyList.concat(basesMap[baseKey].nutrition.macro);
        healthBenefitsKeyList = healthBenefitsKeyList.concat(basesMap[baseKey].nutrition.benefits);
    });

    addons.forEach((addonKey) => {
        ingredientIdentifierObjectList.push({
            name: addonsMap[addonKey].label,
            image: addonsMap[addonKey].img
        });

        microNutrientKeyList = microNutrientKeyList.concat(addonsMap[addonKey].nutrition.micro);
        macroNutrientKeyList = macroNutrientKeyList.concat(addonsMap[addonKey].nutrition.macro);
        healthBenefitsKeyList = healthBenefitsKeyList.concat(addonsMap[addonKey].nutrition.benefits);
    });

    sweetener.forEach((sweetenerKey) => {
        ingredientIdentifierObjectList.push({
            name: sweetenerMap[sweetenerKey].label,
            image: sweetenerMap[sweetenerKey].img
        });

        microNutrientKeyList = microNutrientKeyList.concat(sweetenerMap[sweetenerKey].nutrition.micro);
        macroNutrientKeyList = macroNutrientKeyList.concat(sweetenerMap[sweetenerKey].nutrition.macro);
        healthBenefitsKeyList = healthBenefitsKeyList.concat(sweetenerMap[sweetenerKey].nutrition.benefits);
    });

    microNutrientKeyList = removeDuplicates(microNutrientKeyList);
    macroNutrientKeyList = removeDuplicates(macroNutrientKeyList);
    healthBenefitsKeyList = removeDuplicates(healthBenefitsKeyList);

    const microNutrientObjectList = microNutrientKeyList.map((microNutrientKey) => microNutrientMap[microNutrientKey]);
    const macroNutrientObjectList = macroNutrientKeyList.map((macroNutrientKey) => macroNutrientMap[macroNutrientKey]);
    const healthBenefitsObjectList = healthBenefitsKeyList.map((healthBenefitsKey) => healthBenefitsMap[healthBenefitsKey]);

    const subLabel = [addonsLabel, sweetenerLabel].filter(sub => sub.length > 0 ).join(', ');

    return (
        <div className="App">
            <Topbar />
            <div className="topbar-spacer wave2 upside-down"></div>
            <div className="main-container width-90">
                <div className="product-details-container">
                    <div className="product-details-header">
                        <h2>{baseLabel}</h2>
                        <h5>{addons.length > 0 || sweetener.length > 0 ? `with ${subLabel}` : 'Original' }</h5>
                    </div>

                    <div>
                        {
                            ingredientIdentifierObjectList.map((ingredientObject) => {
                                return <img src={ingredientObject.image} height={20} className={'product-mini-image'} alt="" />
                            })
                        }
                        <span className="product-price">&#x20B9; {productPrice}</span>
                    </div>
                    <br />

                    <div className="nutrition-tag-container">
                        {
                            microNutrientObjectList.map((microNutrientObject) => {
                                return <NutritionTag label={microNutrientObject.label} />
                            })
                        }
                    </div>
                    <br />

                    <div className="nutrition-tag-container">
                        {
                            macroNutrientObjectList.map((macroNutrientObject) => {
                                return <NutritionTag label={macroNutrientObject.label} />
                            })
                        }
                    </div>
                    <br />

                    <div className="nutrition-tag-container">
                        {
                            // TODO: Change to cards/tiles with pictures
                            healthBenefitsObjectList.map((healthBenefitsObject) => {
                                return <NutritionTag label={healthBenefitsObject.label} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function removeDuplicates(arr: any[]) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

export default Product;