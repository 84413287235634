import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { setOrderStepAction } from "../../../actions/ActionsList";
import { Context } from "../../../GlobalStore";
import { ByobStep } from "../../../models/Models";

const Step1ButtonContainer = () => {

    const {store, dispatch} = useContext(Context);

    const navigate = useNavigate();

    const canNavigateToNextStep = store.order.base.length >= 1;
    
    const nextStep = () => {
        navigate('/byob?addon=1');
        dispatch(setOrderStepAction(ByobStep.CHOOSE_ADDON));
    }

    return (
        <div className="button-container">
            <button 
                id="step1button"
                className="btn btn-sm step-button" 
                onClick={nextStep}
                disabled={!canNavigateToNextStep}
            >{ canNavigateToNextStep ? 'Next' : 'Please choose atleast 1 base'} &rarr;</button>
        </div>
    )
}

export default Step1ButtonContainer;