import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { setOrderStepAction } from "../../../actions/ActionsList";
import { Context } from "../../../GlobalStore";
import { ByobStep } from "../../../models/Models";

const Step2ButtonContainer = () => {

    const navigate = useNavigate();
    const {store, dispatch} = useContext(Context);

    const nextStep = () => {
        navigate('/byob?sweetener=1');
        dispatch(setOrderStepAction(ByobStep.CHOOSE_SWEETENER));
    };

    const prevStep = () => {
        navigate(-1);
    }

    return (
        <div className="button-container">
            <button 
                className="btn btn-sm step-button" 
                onClick={prevStep}
            >&larr; Back</button>
            <button 
                className="btn btn-sm step-button" 
                onClick={nextStep}
            >
                {
                    store.order.addons.length > 0 ?
                    <>Next &rarr;</> :
                    <>Skip &rarr;</>
                }
            </button>
        </div>
    )
}

export default Step2ButtonContainer;