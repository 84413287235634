import React, { useContext } from "react";
import { setBaseAction } from "../../actions/ActionsList";
import { Context } from "../../GlobalStore";
import { IngredientsDataMap } from "../../models/Models";
import productConfig from "../../static/products-config.json";
import Note from "../common/Note";
import OptionCard from "./BaseOptionCard";
import { getProductPrice } from "../../utils/PricingUtils";
import PriceIndicator from "./PriceIndicator";

const Step1 = () => {

    const {store, dispatch} = useContext(Context);

    const basesMap: IngredientsDataMap = productConfig.bases;

    const updateBase = (base: string) => {
        let currBase = store.order.base;
        if(currBase.includes(base)) {
            currBase = currBase.filter((value) => value!==base);
        } else {
            currBase.push(base);
            if(currBase.length > 3) {
                currBase.shift();
            }
        }
        dispatch(setBaseAction(currBase));
    };

    const currentItemPrice = getProductPrice(store.order.base, store.order.addons, store.order.sweetener);

    return (
        <>
            <div className="byob-step" id={"byob-step-1"}>
                BYOB / Choose Base<br/>
                <span className="step-subtitle">Choose upto 3 bases for your nut butter</span>
            </div>
            <br />
            <PriceIndicator price={currentItemPrice} />
            <br />
            <div className="base-options-container">
                <div className="row">
                    {
                        Object.keys(basesMap).map((base) => {
                            return (
                                <div key={base} className="col">
                                    <OptionCard 
                                        value={base} 
                                        selected={store.order.base.includes(base)}
                                        onClick={updateBase}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <br />
            <Note title="Note" content="Each unit sold weighs ~350g. Price is normalised to 350g when choosing multiple bases." />
            <br />
        </>
    )
};

export default Step1;