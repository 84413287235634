import { useContext } from "react";
import { removeFromCartAction, setItemQuantityAction } from "../../actions/ActionsList";
import { Context } from "../../GlobalStore";
import { getProductPrice } from "../../utils/PricingUtils";
import productConfig from "../../static/products-config.json";
import { IngredientsDataMap } from "../../models/Models";
import { stringifyCartItem } from "../../utils/CartUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";

const CartItem = (props: { id: number, base: string[], addons: string[], sweetener: string[], quantity: number, mode?: string }) => {
    const { id, base, addons, sweetener, quantity, mode } = props;

    const basesMap: IngredientsDataMap = productConfig.bases;
    const addonsMap: IngredientsDataMap = productConfig.addons;
    const sweetenerMap: IngredientsDataMap = productConfig.sweeteners;

    const baseLabel = base.map((baseKey) => {
        return basesMap[baseKey].label
    }).join(', ');

    const addonsLabel = addons.map((addonKey) => {
        return addonsMap[addonKey].label
    }).join(', ');

    const sweetenerLabel = sweetener.map((sweetenerKey) => {
        return sweetenerMap[sweetenerKey].label
    }).join(', ');

    const subLabel = [addonsLabel, sweetenerLabel].filter(sub => sub.length > 0 ).join(', ');

    const {store, dispatch} = useContext(Context);

    const setItemQuantity = (value: string) => {
        dispatch(setItemQuantityAction(id, parseInt(value)));
    }

    const productPrice = getProductPrice(base, addons, sweetener);

    const cartItemString = stringifyCartItem({
        index: -1,
        base: base,
        addons: addons,
        sweetener: sweetener,
        quantity: quantity
    });

    const productLink = `/product?${cartItemString}`

    return (
        <div className="cart-item">
            <div className="cart-item-details">
                <div className="base-label">{baseLabel} butter</div>
                <div className="addons-label">{addons.length > 0 || sweetener.length > 0 ? `with ${subLabel}` : 'Original' }</div>
                <span className="cart-item-weight">350g</span>
                <span className="cart-item-product-price">&#x20B9; {productPrice}</span>
                <span className="cart-item-product-link">
                    <a href={productLink}><FontAwesomeIcon icon={faCircleInfo}/></a>
                </span>
                {
                    mode !== 'order-view' && 
                    <span className="cart-item-remove" onClick={() => {
                        logEvent(analytics, "remove_from_cart", {
                            base: base,
                            addons: addons,
                            sweetener: sweetener,
                            price: productPrice
                        })
                        dispatch(removeFromCartAction(id));
                    }}>Remove</span>
                }
            </div>

            <div>
                <span className="small-bold">Quantity</span>
                {
                    (mode === 'order-view') ?
                    <div>{quantity}</div> :
                    <select className="form-select form-select-sm" value={quantity} onChange={(e) => setItemQuantity(e.currentTarget.value)}>
                    {
                        [1,2,3,4,5,6,7,8,9,10].map((i) => {
                            return <option value={i}>{i}</option>
                        })
                    }
                    </select>
                }
            </div>

            <div className="cart-item-price">
                &#x20B9; {productPrice * quantity}
            </div>
        </div>  
    );
};

export default CartItem;