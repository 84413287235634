import React, { useContext } from "react";
import { Context } from "../../GlobalStore";
import { getCartPrice, getGSTCharge, getShippingFee } from "../../utils/PricingUtils";
import CartItem from "../cartChildComponents/CartItem";
import Note from "../common/Note";

const CartItemsContainer = () => {
    
    const {store} = useContext(Context);

    const cartPrice = getCartPrice(store.cart);
    const shipping = getShippingFee(store.checkout.personal.state, store.cart);
    const tax = getGSTCharge(cartPrice + shipping);
    
    return (
        <>
            <div id={'cart-items'}>
                {
                    Object.entries(store.cart).map(([key, value]) => {
                        return <CartItem key={key} id={value.index} base={value.base} quantity={value.quantity} addons={value.addons}  sweetener={value.sweetener} />
                    })
                }
            </div>

            <div className="payment-details-parent-container">
                <div className="payment-details-container">
                    <div>Shipping Charge</div>
                    <div>&#x20B9; {shipping}</div>
                </div>
                <div className="payment-details-container">
                    <div>GST @ 12%</div>
                    <div>&#x20B9; {tax}</div>
                </div>
            </div>

            <br />

            <Note title="Free Shipping" content="Get free shipping for orders above INR 1000 in cart value or deliveries in Tamil Nadu, Telangana and Karnataka" />
        </>
    )
};

export default CartItemsContainer;