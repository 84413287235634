import { Personal } from "../models/Models";

const PROD_BASE = "https://asia-south1-superbutter-5246f.cloudfunctions.net";
// const TEST_BASE = "http://localhost:5000/superbutter-5246f/asia-south1"

export const BASE_URL = PROD_BASE;

export const personalInitialState: Personal = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailId: '',
    addressLine1: '',
    addressLine2: '',
    pincode: '',
    city: '',
    state: ''
};

export const PayementGatewayDetails = {
    salt: process.env.REACT_APP_PAYU_SALT,
    merchantKey: process.env.REACT_APP_PAYU_MERCHANT_KEY,
    furl: BASE_URL + "/paymentFailure",
    surl: BASE_URL + "/paymentSuccess"
};