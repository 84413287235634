import { logEvent, setCurrentScreen } from "firebase/analytics";
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setOrderStepAction } from "../actions/ActionsList";
import { analytics } from "../firebaseConfig";
import { Context } from "../GlobalStore";
import { ByobStep } from "../models/Models";
import ByobProgressIndicator from "./byobStepComponents/ByobProgressIndicator";
import Step1ButtonContainer from "./byobStepComponents/byobStepButtonComponents/Step1ButtonContainer";
import Step2aButtonContainer from "./byobStepComponents/byobStepButtonComponents/Step2aButtonContainer";
import Step2ButtonContainer from "./byobStepComponents/byobStepButtonComponents/Step2ButtonContainer";
import Step1 from "./byobStepComponents/Step1";
import Step2 from "./byobStepComponents/Step2";
import Step2a from "./byobStepComponents/Step2a";
import Topbar from "./navigationBarComponents/Topbar";

const Byob = () => {

    

    const [searchparams] = useSearchParams();

    const {store, dispatch} = useContext(Context);
    const step: ByobStep = store.order.step;

    let addonStep = searchparams.get('addon');
    let sweetenerStep = searchparams.get('sweetener'); 

    useEffect(() => {
        if(addonStep && store.order.base.length > 0) {
            dispatch(setOrderStepAction(ByobStep.CHOOSE_ADDON));
        } else if (sweetenerStep && store.order.base.length > 0) {
            dispatch(setOrderStepAction(ByobStep.CHOOSE_SWEETENER));
        } else {
            dispatch(setOrderStepAction(ByobStep.CHOOSE_BASE));
            // navigate('/byob');
        }
    }, [searchparams]);

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: "byob", 
            firebase_screen_class: ""
        });
    }, []);

    const ByobStepComponent = () => {
        switch(step) {
            case ByobStep.CHOOSE_BASE: return <Step1 />;
            case ByobStep.CHOOSE_ADDON: return <Step2 />;
            case ByobStep.CHOOSE_SWEETENER: return <Step2a />;
            default: return <Step1 />;
        }
    }

    const ByobStepButtonContainer = () => {
        switch(step) {
            case ByobStep.CHOOSE_BASE: return <Step1ButtonContainer />;
            case ByobStep.CHOOSE_ADDON: return <Step2ButtonContainer />;
            case ByobStep.CHOOSE_SWEETENER: return <Step2aButtonContainer />;
            default: return <Step1ButtonContainer />;
        }
    }

    return (
        <div className="App">
            <Topbar />
            <div className="topbar-spacer wave2 upside-down"></div>
            <div className="main-container width-90">
                <br />
                <ByobProgressIndicator />
                <div id={'byob-step-content'}>
                    <ByobStepComponent />
                </div>
                <br />
            </div>
            <div className="bottom-bar-spacer"></div>
            <ByobStepButtonContainer />
        </div>
    )
};

export default Byob;