import { logEvent } from "firebase/analytics";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setPersonalAction, setCheckoutStepAction } from "../../actions/ActionsList";
import { analytics } from "../../firebaseConfig";
import { Context } from "../../GlobalStore";
import { CheckoutStep, Personal } from "../../models/Models";
import { getPincodeDetails } from "../../services/PincodeVerificationService";
import { StringKeyBooleanValueObject } from "../../types/Types";
import Note from "../common/Note";

const Step3 = () => {

    const {store, dispatch} = useContext(Context);
    const navigate = useNavigate();
    const [personal, setPersonal] = useState(store.checkout.personal);

    const nextStep = () => {
        logEvent(analytics, "add_shipping_info");
        dispatch(setPersonalAction(personal));
        dispatch(setCheckoutStepAction(CheckoutStep.PAYMENT));
    };
    const prevStep = () => navigate('/cart');

    const validityMap = getFieldValidityMap(personal);
    const allFieldsValid = areAllFieldsValid(validityMap);

    const [pincodeVerificationLoading, setPincodeVerififcationLoading] = useState(false);

    const verifyPincode = () => {
        setPincodeVerififcationLoading(true);

        getPincodeDetails(personal.pincode).then((result) => {

            console.log(result);
            setPersonal({ ...personal, state: result.state, city: result.city });
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setPincodeVerififcationLoading(false);
        });
    }

    return (
        <>
        <div className="width-90">
            <div className="byob-step" id={"byob-step-1"}>
                Checkout / Shipping<br/>
                <span className="step-subtitle">Please provide your shipping details</span>
            </div>
            <br />
            <div className="base-options-container">
                <div className="input-group">
                    <span className="input-group-text">Name</span>
                    <input 
                        type="text" aria-label="First name" 
                        className={getFieldClass(validityMap.firstName)} placeholder="First"
                        value={personal.firstName}
                        onChange={(e) => setPersonal({...personal, "firstName": e.target.value})}
                        required />
                    <input 
                        type="text" aria-label="Last name" 
                        className={getFieldClass(validityMap.lastName)} placeholder="Last"
                        value={personal.lastName}
                        onChange={(e) => setPersonal({...personal, "lastName": e.target.value})}
                        required />
                </div>
                <br />
                <div className="form-floating mb-3">
                    <input 
                        type="number" className={getFieldClass(validityMap.phoneNumber)}
                        id="phoneNumber" placeholder="00000 00000"
                        value={personal.phoneNumber}
                        onChange={(e) => setPersonal({...personal, "phoneNumber": e.target.value})}
                        required />
                    <label htmlFor="phoneNumber">Phone Number</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="email" className={getFieldClass(validityMap.emailId)}
                        id="emailId" placeholder="abcd@superbutter.in"
                        value={personal.emailId}
                        onChange={(e) => setPersonal({...personal, "emailId": e.target.value})}
                        required />
                    <label htmlFor="emailId">E-Mail</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={getFieldClass(validityMap.addressLine1)}
                        id="addressLine1" placeholder="Address Line 1"
                        value={personal.addressLine1}
                        onChange={(e) => setPersonal({...personal, "addressLine1": e.target.value})}
                        required />
                    <label htmlFor="addressLine1">Address Line 1</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={getFieldClass(validityMap.addressLine2)}
                        id="addressLine2" placeholder="Address Line 2" 
                        value={personal.addressLine2}
                        onChange={(e) => setPersonal({...personal, "addressLine2": e.target.value})}
                        required />
                    <label htmlFor="addressLine2">Address Line 2</label>
                </div>

                <div className="form-floating mb-3">
                    <input 
                        type="text" className={getFieldClass(validityMap.city)}
                        id="city" placeholder="City"
                        value={personal.city}
                        onChange={(e) => setPersonal({...personal, "city": e.target.value})}
                        required />
                    <label htmlFor="city">City</label>
                </div>

                <div className="input-group has-validation">
                    <div className="form-floating">
                        <input 
                            type="number" className={getFieldClass(validityMap.pincode)}
                            id="pincode" placeholder="Pincode"
                            value={personal.pincode}
                            onChange={(e) => { 
                                setPersonal({...personal, "pincode": e.target.value, "state": ""});
                            }}
                            required />
                        <label htmlFor="pincode">Pincode</label>
                    </div>
                    <span className="input-group-text">
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2" 
                            disabled={!validityMap.pincode || pincodeVerificationLoading}
                            onClick={verifyPincode}>
                                {
                                    pincodeVerificationLoading ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> :
                                    'Verify'
                                }
                        </button>
                    </span>
                    {!validityMap.state && <div className="invalid-feedback">Please enter a valid pincode</div>}
                </div>
                
                <br />

                <div className="form-floating mb-3">
                    <input 
                        type="text" className={getFieldClass(validityMap.state)}
                        id="state" placeholder="State"
                        value={personal.state}
                        disabled />
                    <label htmlFor="state">State</label>
                </div>
                <br />
            </div>
            <Note title="Can't find your city?" content="We are working hard to service as many locations as possible. Until then, refer to our product selection and list down your order. E-mail us at contact@superbutter.in with your order details, desired delivery address and phone number and we will get back to you ASAP." />
            <br />
            </div>
            <div className="bottom-bar-spacer"></div>
            <div className="button-container" id={"step3-button-container"}>
                <button 
                    className="btn btn-dark btn-sm step-button" 
                    onClick={prevStep}
                >&larr; Back to cart</button>
                <button 
                    className="btn btn-dark btn-sm step-button" 
                    onClick={nextStep}
                    disabled={!allFieldsValid}
                >Next &rarr;</button>
            </div>
        </>
    )
};

const areAllFieldsValid = (validityMap: StringKeyBooleanValueObject) => {
    let validity = true;
    for (const fieldValidity in validityMap) {
        validity = validity && validityMap[fieldValidity];
    }
    return validity;
};

const getFieldValidityMap = (personal: Personal) => {
    return {
        firstName: personal.firstName.length > 0,
        lastName: personal.lastName.length > 0,
        phoneNumber: personal.phoneNumber.length === 10,
        emailId: personal.emailId.length > 0 && validateEmail(personal.emailId),
        addressLine1: personal.addressLine1.length > 0,
        addressLine2: personal.addressLine2.length > 0,
        pincode: personal.pincode.length === 6,
        city: personal.city.length > 0,
        state: personal.state.length > 0
    }
}

const getFieldClass = (validity: boolean) => {
    return validity ? "form-control" : "form-control is-invalid";
};

const validateEmail = (email: string) => {
    const result = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if(result) {
        return true;
    }
    return false;
};

export default Step3;