import { useEffect, useReducer } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Byob from './components/Byob';
import { AppState, Context, initialState, reducer } from './GlobalStore';
import Checkout from './components/Checkout';
import Cart from './components/Cart';
import Order from './components/Order';
import Product from './components/Product';

function App() {

  const savedStoreString = localStorage.getItem('superbutter-app-state-v4.1');
  const savedStoreObject = savedStoreString && JSON.parse(savedStoreString);

  const [store, dispatch] = useReducer(reducer, savedStoreObject ?? initialState);

  useEffect(() => {
    let savedStore = {
        ...store,
        checkout: {
            ...store.checkout,
            paymentLoading: false
        }
    } as AppState;
    localStorage.setItem('superbutter-app-state-v4.1', JSON.stringify(savedStore));
  }, [store])

  return (
    <Context.Provider value={{store, dispatch}}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/byob" element={<Byob />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route path="/ingredients" element={<Ingredients />} /> */}
            <Route path="/order">
                <Route index element={<Order />} />
                <Route path={":orderId"} element={<Order />} />
            </Route>
            <Route path="/product" element={<Product />} />
          </Routes>
        </Router>
    </Context.Provider>
  );
}

export default App;
