const StatusBadge = (props: { status: string; }) => {

    const badgeStyle = `status-badge status-${props.status}`;

    return (
        <span className={badgeStyle}>
            {props.status}
        </span>
    )
}

export default StatusBadge;