import React, { useContext } from "react";
import { Context } from "../../GlobalStore";

const CheckoutShippingDetailsTab = () => {

    const {store} = useContext(Context);
    const { personal } = store.checkout;

    return (
        <div className="base-options-container">
                <div className="input-group">
                    <span className="input-group-text">Name</span>
                    <input 
                        type="text" aria-label="First name" 
                        className={'form-control'}
                        value={personal.firstName}
                        disabled />
                    <input 
                        type="text" aria-label="Last name" 
                        className={'form-control'}
                        value={personal.lastName}
                        disabled />
                </div>
                <br />
                <div className="form-floating mb-3">
                    <input 
                        type="number" className={'form-control'}
                        id="phoneNumber" placeholder="00000 00000"
                        value={personal.phoneNumber}
                        disabled />
                    <label htmlFor="phoneNumber">Phone Number</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="email" className={'form-control'}
                        id="emailId" placeholder="abcd@superbutter.in"
                        value={personal.emailId}
                        disabled />
                    <label htmlFor="emailId">E-Mail</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={'form-control'}
                        id="addressLine1" placeholder="Address Line 1"
                        value={personal.addressLine1}
                        disabled />
                    <label htmlFor="addressLine1">Address Line 1</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={'form-control'}
                        id="addressLine2" placeholder="Address Line 2" 
                        value={personal.addressLine2}
                        disabled />
                    <label htmlFor="addressLine2">Address Line 2</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="number" className={'form-control'}
                        id="pincode" placeholder="Pincode"
                        value={personal.pincode}
                        disabled />
                    <label htmlFor="pincode">Pincode</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={'form-control'}
                        id="city" placeholder="City"
                        value={personal.city}
                        disabled />
                    <label htmlFor="city">City</label>
                </div>
                <div className="form-floating mb-3">
                    <input 
                        type="text" className={'form-control'}
                        id="state" placeholder="State"
                        value={personal.state}
                        disabled />
                    <label htmlFor="state">State</label>
                </div>
                
            </div>
    );
};  

export default CheckoutShippingDetailsTab;