const Loading = (props: { title: string; }) => {
    return (
        <div className="loading-container">
            <div className="spinner-border spinner-border-sm text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default Loading;