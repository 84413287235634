
const Note = (props: { title: string; content: string | undefined; }) => {
    return (
        <div className="note">
            <div className="note-title">{props.title}</div>
            <div className="note-content">{props.content}</div>
        </div>
    )
}

export default Note;