import { StringKeyStringValueObject } from "../types/Types";

export enum ByobStep {
    CHOOSE_BASE,
    CHOOSE_ADDON,
    CHOOSE_SWEETENER
};

export enum CheckoutStep {
    BILLING_AND_SHIPPING,
    PAYMENT
}

export enum CheckoutView {
    CHECKOUT_SUMMARY,
    CHECKOUT_ITEMS,
    CHECKOUT_SHIPPING_DETAILS
}

export enum OrderView {
    CART_DETAILS,
    PAYMENT_DETAILS,
    SHIPPING_DETAILS
}

export interface FirebaseTimestamp {
    _seconds: number;
    _nanoseconds: number;
}

export interface Personal extends StringKeyStringValueObject {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailId: string;
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
}

export interface Order {
    base: string[];
    addons: string[];
    sweetener: string[];
    step: ByobStep;
}

export interface CartItem {
    index: number;
    base: string[];
    addons: string[];
    sweetener: string[];
    quantity: number;
}

export interface IngredientDataObject {
    label: string;
    price: number;
    img: string;
    nutrition: {
        macro: string[];
        micro: string[];
        benefits: string[];
    };
}

export interface NutritionDataObject {
    label: string;
}

export interface IngredientsDataMap {
    [key: string]: IngredientDataObject;
}

export interface NutritionDataMap {
    [key: string]: NutritionDataObject;
}

export interface OrderDetails {
    firstName: string;
    lastName: string;
    deliveryStatus: string;
    paymentStatus: string;
    orderStatus: string;
    addr1: string;
    addr2: string;
    city: string;
    state?: string;
    pincode: number;
    cart: string[];
    email: string;
    phone: string;
    tax: number;
    shippingFee: number;
    cartAmount: number;
    totalAmount: number;
    orderedOn: FirebaseTimestamp;
    trackingLink?: string;
    consignment?: string;
    cancelReason?: string;
}