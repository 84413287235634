export const getPincodeDetails = async (pincode: string) => {
    const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
    const data = await response.json();
    const result = data[0];

    if (result.Status === "Success") {
        return {
            status: true,
            state: result.PostOffice[0].State,
            city: result.PostOffice[0].Division
        }
    } else {
        return {
            status: false,
            state: '',
            city: ''
        }
    }
}