import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faDna, faFaceGrinWide, faFan } from "@fortawesome/free-solid-svg-icons";


const About = () => {
    return (
        <div id={'learn-more'}>
            <h1>What makes us super?</h1>
            <br />

            <div className='row learn-more-card-container'>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='row'>
                            <div className='col-9'>
                                <div className='learn-more-card-text'>
                                    <h4>Tailor Made</h4>
                                    <div>You are unique and your food should be too!</div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='learn-more-card-graphic' id="tailor-made">
                                    <FontAwesomeIcon icon={faFan} color={'#5d3c85'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='row'>
                            <div className='col-9'>
                                <div className='learn-more-card-text'>
                                    <h4>Assorted Ingredients</h4>
                                    <div>Get creative! Mix and match from our selection of 20+ ingredients</div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='learn-more-card-graphic' id="assorted">
                                    <FontAwesomeIcon icon={faFaceGrinWide} color={'#5d3c85'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row learn-more-card-container'>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='row'>
                            <div className='col-9'>
                                <div className='learn-more-card-text'>
                                    <h4>Natural</h4>
                                    <div>Indulge in pure organic goodness with no additives and preservatives</div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='learn-more-card-graphic' id="natural">
                                    <FontAwesomeIcon icon={faLeaf} color={'#5d3c85'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='row'>
                            <div className='col-9'>
                                <div className='learn-more-card-text'>
                                    <h4>Nourishing</h4>
                                    <div>Fuel your body with the most bio-available nutrient dense butter thanks to our special preparation technique</div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='learn-more-card-graphic' id="nourishing">
                                    <FontAwesomeIcon icon={faDna} color={'#5d3c85'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
};

export default About;