import { logEvent } from "firebase/analytics";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { addToCartAction, setAddonAction, setBaseAction, setOrderStepAction, setSweetenerAction } from "../../../actions/ActionsList";
import { analytics } from "../../../firebaseConfig";
import { Context } from "../../../GlobalStore";
import { ByobStep, CartItem } from "../../../models/Models";


const Step2aButtonContainer = () => {

    const navigate = useNavigate();
    const {store, dispatch} = useContext(Context);

    const resetOrderStep = () => {
        dispatch(setBaseAction([]));
        dispatch(setAddonAction([]));
        dispatch(setSweetenerAction([]));
        dispatch(setOrderStepAction(ByobStep.CHOOSE_BASE));
    }

    const nextStep = () => {

        let itemIndexList = Object.keys(store.cart).map((i) => parseInt(i));
        let lastItemIndex = itemIndexList[itemIndexList.length - 1];

        let cartItem: CartItem = {
            index: (itemIndexList.length === 0) ? 0 : (store.cart[lastItemIndex].index + 1),
            base: store.order.base,
            addons: store.order.addons,
            sweetener: store.order.sweetener,
            quantity: 1
        };

        logEvent(analytics, "add_to_cart", {
            base: store.order.base,
            addons: store.order.addons,
            sweetener: store.order.sweetener,
        });

        dispatch(addToCartAction(cartItem));
        resetOrderStep();
        navigate('/cart');
    };

    const prevStep = () => {
        navigate(-1);
    }
    return (
        <div className="button-container">
            <button 
                className="btn btn-sm step-button" 
                onClick={prevStep}
            >&larr; Back</button>
            <button 
                className="btn btn-sm step-button" 
                onClick={nextStep}
            >+ Add to cart</button>
        </div>
    )
}

export default Step2aButtonContainer;