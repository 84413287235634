import { CartItem } from "../models/Models";
import productConfig from "../static/products-config.json";

export const getProductPrice = (base: string[], addons: string[], sweeteners: string[]) => {
    // TODO: Replace with product pricing logic

    if (base.length === 0) {
        return 0;
    }

    const productConfigObject = productConfig as {[key: string]: any};

    let price = 0;

    base.forEach((base) => {
        const baseItem = productConfigObject["bases"][base];
        price+=baseItem["price"];
    });

    price = price / base.length;

    addons.forEach((addon) => {
        const addonItem = productConfigObject["addons"][addon];
        price+=addonItem["price"];
    });

    sweeteners.forEach((sweetener) => {
        const addonItem = productConfigObject["sweeteners"][sweetener];
        price+=addonItem["price"];
    })

    return Math.ceil(price*100)/100;
}

export const getCartPrice = (cart: { [key: number]: CartItem }) => {
    let price = 0;
    Object.entries(cart).forEach(([key, cartItem]) => {
        price+=getProductPrice(cartItem["base"], cartItem["addons"], cartItem["sweetener"]) * cartItem["quantity"];
    });
    return Math.ceil(price*100)/100;
}

export const getCartPriceWithTax = (cart: { [key: number]: CartItem }, state: string) => {
    const cartPrice = getCartPrice(cart);
    const shipping = getShippingFee(state, cart);
    const tax = getGSTCharge(cartPrice + shipping);
    const totalamount = cartPrice + shipping + tax;
    return Math.ceil(totalamount*100)/100;
}

export const getShippingFee = (state: string, cart: { [key: number]: CartItem }) => {
    if (["Tamil Nadu", "Telangana", "Karnataka"].includes(state)) {
        return 0;
    } else if (getCartPrice(cart) > 1000) {
        return 0;
    } else {
        return 100;
    }
}

export const getGSTCharge = (amount: number) => {
    const tax = 0.12 * amount;
    return Math.ceil(tax*100)/100
}