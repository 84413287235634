import { IngredientsDataMap, IngredientDataObject } from "../../models/Models";
import productConfig from "../../static/products-config.json";

const OptionCard = (props: {value: string, selected?: boolean, onClick?: Function}) => {
    
    const {value, onClick} = props;

    const bases: IngredientsDataMap = productConfig.bases;
    const base: IngredientDataObject = bases[value];

    const selected = props.selected ?? false;
    const baseClass = "base-option-card card";
    const selectedClass = "base-option-card card option-selected";

    return (
        <div onClick={() => onClick?.(value)} 
            className={(selected) ? selectedClass : baseClass}>
            {
                (base.img && base.img.length > 0) &&
                <img src={base.img} height={20} alt="" />
            }
            <br />
            <div>{base.label}</div>
            <div className="option-card-price-line"> &#x20B9; {base.price} / 350g</div>
        </div>
    )
};

export default OptionCard;