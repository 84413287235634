import { Link } from "react-router-dom";
import '../App.css';
import About from "./About";
import BlobAnimation from "./homePageChildComponents/BlobAnimation";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import logo from "../static/images/super512.png";
import Topbar from "./navigationBarComponents/Topbar";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

const Home = () => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "landing", 
        firebase_screen_class: ""
    });

    return (
        <div className="App" id={'home'}>
            <Topbar />
            <header className="App-header" id={"header-fold"}>
                <div className="row" style={{zIndex: 6, width: '90%'}}>
                    <div className="col-md-6">
                        <div className="home-cta-container">
                            <h1>Go nuts with superbutter!</h1>
                            <h6>Organic, Customisable, Blended Nut Butters</h6>

                            <Link to={'/byob'}>
                                <button className={'btn btn-sm'} id={'byob-button'}>Build your own butter</button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        
                    </div>

                </div>
                <BlobAnimation />
            </header>
            <div className="spacer wave"></div>
            <section className="App-header" id={"learnmore"}>
                <About />
            </section>
            <div className="topbar-spacer wave3"></div>
            <footer id={"footer"}>
                <div className="row">
                    <div className="col-sm-4 footer-content footer-logo">
                        <img src={logo} width={200} alt={''} />
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-6 footer-content footer-navigation">
                                Navigate
                                <br />
                                <Link to="/cart">Cart</Link><br />
                                <Link to="/byob">Build your own butter</Link><br />
                                <HashLink to="#learnmore">Learn More</HashLink><br />
                            </div>
                            <div className="col-6 footer-content footer-contact">
                                Contact
                                <br />
                                <a href="https://www.instagram.com/superbutter.in/" target={"_blank"} rel={"noreferrer"}>
                                    <FontAwesomeIcon icon={faInstagram} color={"#fffaed"} />
                                </a>

                                <a href="https://www.facebook.com/superbutter.in/" target={"_blank"} rel={"noreferrer"}>
                                    <FontAwesomeIcon icon={faFacebook} color={"#fffaed"} />
                                </a>

                                <a href="mailto:contact@superbutter.in">
                                    <FontAwesomeIcon icon={faEnvelope} color={"#fffaed"} />
                                </a>
                                <br />
                                <div className="contact-email-container">
                                    <div>Write to us at</div>
                                    <div id={"contact-email-id"}>contact@superbutter.in</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;