import { faSkull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "firebase/analytics";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { analytics } from "../firebaseConfig";
import { Context } from "../GlobalStore";
import CartItem from "./cartChildComponents/CartItem";
import Topbar from "./navigationBarComponents/Topbar";

const Cart = () => {

    const navigate = useNavigate();

    const { store } = useContext(Context);

    return (
        <div className="App">
            <Topbar />
            <div className="topbar-spacer wave2 upside-down"></div>
            <div className="main-container width-90">
                {
                    Object.keys(store.cart).length > 0 ?
                    <div id={'cart-details'}>
                        <div className="row">
                            <div className="col-sm-12" id={'cart-items'}>
                                <h5>Your cart has {Object.keys(store.cart).length} item(s)</h5>
                                {
                                    Object.entries(store.cart).map(([key, value]) => {
                                        return <CartItem key={key} id={parseInt(key)} base={value.base} addons={value.addons} sweetener={value.sweetener} quantity={value.quantity} />
                                    })
                                }
                            </div>
                        </div>  
                    </div> :
                    <div id={'empty-cart'}>
                        <div className="empty-cart-content">
                                <h1>
                                    <FontAwesomeIcon icon={faSkull} />
                                </h1>
                                <h1>Your cart is empty.</h1>
                                <br />
                                <Link to='/byob'>
                                    <button className="btn step-button">+ Click here to add butter</button>
                                </Link> 
                        </div>
                        
                    </div>
                }
            </div>
            <div className="bottom-bar-spacer"></div>
            {
                Object.keys(store.cart).length > 0 && 
                <div className="button-container">
                    <button
                        id={'add-more-items'}
                        className="btn btn-dark btn-sm step-button" 
                        onClick={() => navigate('/byob')}
                    >+ Add more items</button>

                    <button 
                    className="btn btn-dark btn-sm step-button"
                    id={'cart-checkout'}
                    onClick={() => {
                        logEvent(analytics, "begin_checkout");
                        navigate('/checkout');
                    }}>Proceed to checkout &rarr;</button>
                </div>
            }
        </div>
    )
};

export default Cart;