import React, { useContext } from "react";
import { setAddonAction } from "../../actions/ActionsList";
import { Context } from "../../GlobalStore";
import { IngredientsDataMap } from "../../models/Models";
import productConfig from "../../static/products-config.json";
import { getProductPrice } from "../../utils/PricingUtils";
import OptionCard from "./AddonsOptionCard";
import PriceIndicator from "./PriceIndicator";

const Step2 = () => {

    const {store, dispatch} = useContext(Context);

    const addonsMap: IngredientsDataMap = productConfig.addons;

    const updateAddons = (addon: string) => {
        let currAddons = store.order.addons;
        if(currAddons.includes(addon)) {
            currAddons = currAddons.filter((value) => value!==addon);
        } else {
            currAddons.push(addon);
            if(currAddons.length > 3) {
                currAddons.shift();
            }
        }
        dispatch(setAddonAction(currAddons));
    };

    const currentItemPrice = getProductPrice(store.order.base, store.order.addons, store.order.sweetener);

    return (
        <>
            <div className="byob-step" id={"byob-step-1"}>
                BYOB / Choose Add-ons<br/>
                <span className="step-subtitle">Choose upto 3 toppings and flavours for your nut butter</span>
            </div>
            <br />
            <PriceIndicator price={currentItemPrice} />
            <br />
            <div className="base-options-container">
                <div className="row">
                    {
                        Object.keys(addonsMap).map((addon) => {
                            return (
                                <div key={addon} className="col">
                                    <OptionCard 
                                        value={addon} 
                                        selected={store.order.addons.includes(addon)}
                                        onClick={updateAddons}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
};

export default Step2;