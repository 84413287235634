import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../GlobalStore";

const CheckoutProgressIndicator = () => {

    const { store } = useContext(Context);
    const { step } = store.checkout;

    const [progress, setProgress] = useState((step+1) * 50);

    useEffect(() => setProgress((step+1) * 50), [step]);

    return (
        <div className="progress">
            <div 
                className="progress-bar bg-warning progress-bar-striped progress-bar-animated" 
                role="progressbar"
                style={{ width: progress + '%' }}
                aria-valuenow={progress} 
                aria-valuemin={0} 
                aria-valuemax={100}></div>
        </div>
    )
};

export default CheckoutProgressIndicator;