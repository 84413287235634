import { BASE_URL } from "../constants/Constants";
import { CartItem, Personal } from "../models/Models";
import { stringifyCartItem } from "../utils/CartUtils";
import { getCartPrice, getCartPriceWithTax, getGSTCharge, getShippingFee } from "../utils/PricingUtils";

export const createOrder = async (cart: CartItem[], personal: Personal) => {
    
    const cartPrice = getCartPrice(cart);
    
    const requestObject = {
        addr1: personal.addressLine1,
        addr2: personal.addressLine2,
        city: personal.city,
        pincode: personal.pincode,
        state: personal.state,
        phone: personal.phoneNumber,
        email: personal.emailId,
        firstName: personal.firstName,
        lastName: personal.lastName,
        cart: cart.map((item) => stringifyCartItem(item)),
        cartAmount: cartPrice,
        shippingFee: getShippingFee(personal.state, cart),
        tax: getGSTCharge(cartPrice),
        totalAmount: getCartPriceWithTax(cart, personal.state)
    }

    console.log('Create Order Request: ', requestObject);

    const response = await fetch(BASE_URL + '/createOrder', {
        method: 'post',
        body: JSON.stringify(requestObject)
    });

    const data = await response.json();

    return data;
}

export const getOrderDetails = async (orderId: string) => {
    const response = await fetch(`${BASE_URL}/getOrderDetails?orderId=${orderId}`);
    const data = await response.json();
    return data;
}