import { createContext, Dispatch } from "react";
import { Actions } from "./actions/ActionsList";
import { personalInitialState } from "./constants/Constants";
import { ByobStep, CartItem, CheckoutStep, CheckoutView, Order, Personal } from "./models/Models";

export interface AppState {
    order: Order;
    cart: {
        [key: number]: CartItem
    }
    checkout: {
        personal: Personal;
        step: CheckoutStep;
        view: CheckoutView;
        paymentLoading: boolean;
    }
}

export interface AppContext {
    store: AppState;
    dispatch: Dispatch<Action>;
}

export interface Action { 
    type: string;
    payload?: any;
}

export const initialState: AppState = {
    order: {
        base: [],
        addons: [],
        sweetener: [],
        step: ByobStep.CHOOSE_BASE
    },
    cart: {},
    checkout: {
        personal: personalInitialState,
        step: CheckoutStep.BILLING_AND_SHIPPING,
        view: CheckoutView.CHECKOUT_ITEMS,
        paymentLoading: false
    }
};

export const reducer = (state: AppState, action: Action) => {
    switch(action.type) {
        case Actions.SET_ORDER_STEP:
            return {...state, order: {...state.order, step: action.payload}};
        case Actions.SET_BASE:
            return {...state, order: { ...state.order, base: action.payload }};
        case Actions.SET_ADDON:
            return {...state, order: { ...state.order, addons: action.payload }};
        case Actions.SET_SWEETENER:
            return {...state, order: { ...state.order, sweetener: action.payload }};
        case Actions.SET_PERSONAL:
            return {...state, checkout: {...state.checkout, personal: action.payload}};
        case Actions.SET_CHECKOUT_STEP:
            return {...state, checkout: {...state.checkout, step: action.payload}};
        case Actions.ADD_TO_CART: {
            const newCartItem = action.payload;
            const newIndex = newCartItem.index;

            const newCart = {...state.cart};
            newCart[newIndex] = newCartItem;

            return {...state, cart: {...newCart}};
        }
        case Actions.REMOVE_FROM_CART: {
            const newCart = {...state.cart};
            delete newCart[action.payload];

            return {...state, cart: {...newCart}};
        }
        case Actions.SET_ITEM_QUANTITY_ACTION: {
            const {itemId, quantity} = action.payload;

            const newCart = {...state.cart};
            newCart[itemId].quantity = quantity;

            return {...state, cart: {...newCart}};
        }
        case Actions.RESET_ORDER:
            return {...state, order: initialState.order};
        case Actions.SET_CHECKOUT_VIEW:
            return {...state, checkout: {...state.checkout, view: action.payload}};
        case Actions.RESET:
            return {
                ...state,
                order: initialState.order,
                cart: initialState.cart,
                checkout: {
                    ...initialState.checkout,
                    personal: state.checkout.personal
                }
            }
        case Actions.SET_PAYMENT_LOADING:
            return {
                ...state,
                order: initialState.order,
                cart: initialState.cart,
                checkout: {
                    ...initialState.checkout,
                    personal: state.checkout.personal,
                    paymentLoading: true
                }
            }
        default:
            return state;
    }
}


export const Context = createContext({ store: initialState, dispatch: () => {}} as AppContext);