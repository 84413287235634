import React, { useContext } from "react";
import { setSweetenerAction } from "../../actions/ActionsList";
import { Context } from "../../GlobalStore";
import { IngredientsDataMap } from "../../models/Models";
import productConfig from "../../static/products-config.json";
import { getProductPrice } from "../../utils/PricingUtils";
import PriceIndicator from "./PriceIndicator";
import OptionCard from "./SweetenerOptionCard";

const Step2a = () => {

    const {store, dispatch} = useContext(Context);

    const sweetenersMap: IngredientsDataMap = productConfig.sweeteners;

    const updateSweetener = (sweetener: string) => {
        let currSweetener = store.order.sweetener;
        if(currSweetener.includes(sweetener)) {
            currSweetener = currSweetener.filter((value) => value!==sweetener);
        } else {
            currSweetener = [sweetener];
        }
        dispatch(setSweetenerAction(currSweetener));
    };

    const currentItemPrice = getProductPrice(store.order.base, store.order.addons, store.order.sweetener);

    return (
        <>
            <div className="byob-step" id={"byob-step-1"}>
                BYOB / Choose Sweetener<br/>
                <span className="step-subtitle">Choose from 3 different sweetening options to sweeten your butter or skip for unsweetened</span>
            </div>
            <br />
            <PriceIndicator price={currentItemPrice} />
            <br />
            <div className="base-options-container">
                <div className="row">
                    {
                        Object.keys(sweetenersMap).map((sweetener) => {
                            return (
                                <div key={sweetener} className="col">
                                    <OptionCard 
                                        value={sweetener} 
                                        selected={store.order.sweetener.includes(sweetener)}
                                        onClick={updateSweetener}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <br />
        </>
    )
};

export default Step2a;